/* Loader styles */
.loaderDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loaderDiv.hidden {
  display: none;
}

.pageLoader {
  width: 48px;
  height: 48px;
}
